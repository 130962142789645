import axios from 'axios';
import lineChart from '@/components/charts/lineChart.vue';
import Loader from '@/components/Loader/Loader.vue';
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
export default {
    name: 'PurchaseJourney',
    components: {
        Loader, lineChart,dualAxisChart
    },
    data() {
        return {
            loader: false,
            dateRange: {
                startDate: moment().subtract(4, 'week').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
           viewFirstPurchaseJourneyData:'',
           viewSecondPurchaseJourneyData:'',
           isTableSubHeading:false,
           firstPurchaseGraphData:{},
           secondPurchaseGraphData:{}
        }
    },

    created() {
        // this.initializeModal();
        this.dataAPIs();
    },
   
    methods: {
        dataAPIs(){
            $("#reports_table_first").DataTable().destroy();
            $("#reports_table_second").DataTable().destroy();

            this.getFirstPurchaseJourneyData();
            this.getSecondPurchaseJourneyData();

        },

        formatNumber(num) {
            if (isNaN(num)) { return num }
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        },

        getFirstPurchaseJourneyData() {
            this.viewFirstPurchaseJourneyData = {}
            this.firstPurchaseGraphData=''
            this.loader = true
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                journey_type: "journey1"
            }
            const url = "/api/v1/analytics/purchasejourney"
            axios.post(url, payload).then(res => {
                this.viewFirstPurchaseJourneyData = res.data
                this.$nextTick(() => {
                    this.processTable('#reports_table_first')
                })
                this.firstPurchaseGraphData = this.processPurchaseJourneyGraph(res.data);
                this.loader = false


            }).catch((err) => {
                console.log("Err Plugin PlayAccountsData")
                this.loader = false
            })
        },

        getSecondPurchaseJourneyData() {
            this.viewSecondPurchaseJourneyData = {}
            this.secondPurchaseGraphData = ''
            this.loader = true
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                journey_type: "journey2"
            }
            const url = "/api/v1/analytics/purchasejourney"
            axios.post(url, payload).then(res => {
                this.viewSecondPurchaseJourneyData = res.data
                this.$nextTick(() => {
                    this.processTable('#reports_table_second')
                })
                this.secondPurchaseGraphData = this.processPurchaseJourneyGraph(res.data);
                this.loader = false


            }).catch((err) => {
                console.log("Err Plugin PlayAccountsData")
                this.loader = false
            })
        },

        processPurchaseJourneyGraph(data){
            let temp ={
                xAxis: data.funnel_data.map(data=>data.funnelStepName),
                disableYLabel: false,
                seriesData:[{
                    name: 'Active users',
                    data: data.funnel_data.map(data=>data.activeUsers)
                }]
            }
            return temp;
        },

        processTable(id) {
            $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                scrollCollapse: true,
                lengthChange: true,
                ordering: false,
                // sorting: [[sortCol, "asc"]],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            $(id + ' thead th:first-child').removeClass('sorting sorting_asc sorting_desc');
        },
    },
}