import moment from 'moment'

const filter = {
    namespaced: true,
    state: {
       dateRange:'',
       menu: [],
       SVAPIDateRangeDate:'',
       SVSelectedDateRange:{
        startDate:'',
        endDate:'',
       },
       AMSAPIDateRangeDate:'',
       amsSelectedDateRange:{
        startDate:'',
        endDate:'',
       },
       isShopify:true,
       metaCretiveFilter:{
        stringType:[[{"operation":"does not contains","field":"Ad Name","value":"Catalog"}],[{"operation":"does not contains","field":"Ad Name","value":"CATALOG"}]],
        numericType:[]
       },
       metaCreativeTimelineDataGraphFilter:{
        interval: "day",
        metric_1: "Impressions",
        metric_2: "Reach",
        name: "Timeline Data",
        page_depth:0,
       }

    },

    mutations: {
        GET_MetaCreatvieTimelineGraphData(state,data){
            state.metaCreativeTimelineDataGraphFilter = ''
            state.metaCreativeTimelineDataGraphFilter = data
        },
        GET_MetaCreatvieFilterBy(state,data){
            state.metaCretiveFilter.stringType = data.stringType
            state.metaCretiveFilter.numericType = data.numericType
        }, 
        GET_SVAPIDateRangeData(state,data){
            state.SVAPIDateRangeDate=data
            state.SVSelectedDateRange.startDate=  moment(state.SVAPIDateRangeDate.end_date).startOf('month').subtract(3,"months").format("YYYY-MM-DD")
            state.SVSelectedDateRange.endDate = moment(state.SVAPIDateRangeDate.end_date).format("YYYY-MM-DD")
        },   
        SET_SELECTED_DATE(state,dateRange){
            state.SVSelectedDateRange.startDate= dateRange.startDate
            state.SVSelectedDateRange.endDate = dateRange.endDate
        }, 
        GET_AMSAPIDateRangeData(state,data){
            state.AMSAPIDateRangeDate=data
            state.amsSelectedDateRange.startDate=  moment(state.AMSAPIDateRangeDate.end_date).format("YYYY-MM-DD")
            state.amsSelectedDateRange.endDate = moment(state.AMSAPIDateRangeDate.end_date).format("YYYY-MM-DD")
        },   
        SET_SELECTED_AMS_DATE(state,dateRange){
            state.amsSelectedDateRange.startDate= dateRange.startDate
            state.amsSelectedDateRange.endDate = dateRange.endDate
        }, 
    },

    actions: {
        setMetaCreatvieTimelineGraphData({commit},data){
            commit("GET_MetaCreatvieTimelineGraphData",data)
        },
        setMetaCreatvieFilterBy({commit},data){
            commit("GET_MetaCreatvieFilterBy",data)
        },
        setSVDateAPIDate({commit},data){
            commit("GET_SVAPIDateRangeData",data)
        },
        setSelectedDate({commit},dateRange){
            commit('SET_SELECTED_DATE',dateRange)
        },
        setAMSDateAPIDate({commit},data){
            commit("GET_AMSAPIDateRangeData",data)
        },
        setSelectedAMSDate({commit},dateRange){
            commit('SET_SELECTED_AMS_DATE',dateRange)
        },
    }
}

export default filter;