import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import Header from '@/components/Header/Header.vue'
import lineChart from '@/components/charts/lineChart.vue'
import drillPieChart from '@/components/charts/drillPieChart.vue'


// api :-
// https://weavr.online/api/summary/campaigns/?start_date=2023-01-22&end_date=2023-02-21
// https://weavr.online/api/comment/
// https://weavr.online/api/summary/aggrigate/?start_date=2023-02-01&end_date=2023-02-21
// https://weavr.online/api/summary/aggrigate/?start_date=2023-01-11&end_date=2023-01-31

export default {
    name: 'Summary',
    components: {
        Header,
        Loader,
        lineChart,
        drillPieChart
    },
    data() {
        return {
            dateRange: {
                startDate: moment().subtract(1, 'week'),
                endDate: moment()
            },
            dataLoader: false,
           
            intervalsList: [
                {
                    key: 'day',
                    label: 'Day'
                },
                {
                    key: 'week',
                    label: 'Week'
                },
                {
                    key: 'month',
                    label: 'Month'
                },
            ],
            selectedInterval: {
                key: 'day',
                label: 'Day'
            },
            tabList:[
                {"key":"campaign", "value": "Campaign Summary"},
                { "key": "adgroup", "value": "Adword Summary" },
                { "key": "device", "value": "Device Summary" },
            ],
            selectedTab:'campaign',
            viewAggrigateData:'',
            isMetricDropdown: false,
            processedTopBoxesData:'',
            metrics: [],
            
        }
    },
    watch: {
        metrics: {
            deep: true,
            handler() {
                this.selectMetric();
            }
        }
    },
    // created(){
    //     window.addEventListener("click", this.close)
    // },
    mounted() {
        this.init();
    },

    // beforeDestroy() {
    //     window.removeEventListener("click", this.close)
    // },
    methods: {
        init() {
            this.formatDateRange();
            this.dataAPIs();
        },

        dataAPIs() {
            this.getAggrigateData();
        },

        formatDateRange() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },

        formatDates() {
            this.formatDateRange();
            this.dataAPIs()
        },

        toggleTab(selectedTab){
            this.selectedTab = selectedTab
            this.formatDateRange();
            this.dataAPIs()
        },

        abbreviateNumber(value){
			return Intl.NumberFormat('en-US', {
				notation: "compact",
				maximumFractionDigits: 2
			}).format(value);
        },

        setInterval(selectedInterval) {
            this.selectedInterval = selectedInterval;
            this.formatDateRange(); 
            this.getAggrigateData()
        },

        selectMetric(metric) {
            this.tableHeader = this.metrics.filter(data => data.isAvailable);
            this.$store.dispatch('store/setFBCreativeMetric', this.tableHeader)
            if ($.fn.DataTable.isDataTable('#reports_table')) {
                $('#reports_table').DataTable().destroy();
            }
            this.$nextTick(() => {
                this.processTable('#reports_table', 1);
            });

        },

        close(event) {
            if (this.metrics.length) {
                if (!this.$refs.metricDropdownRef.contains(event.target)) {
                    this.isMetricDropdown = false
                }
            }

        },

        toggleAdMetricDropdown() {
            if (this.isMetricDropdown) {
                this.isMetricDropdown = false
            }
            else {
                this.isMetricDropdown = true
            }
        },

        generateMetricsForFilter() {
            let selectedMetrics = ['impressions']
            this.metrics = this.viewAggrigateData.headers.map(data => {
                return {
                    isAvailable: selectedMetrics.includes(data.key) ? true : false,
                    label: data.label,
                    key: data.key
                }
            })
            this.tableHeader = selectedMetrics
        },

        decodeHTML(html) {
            let txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value; // decoder for HTML-encoded strings.
        },

        getAggrigateData() {
            let url = '/api/v2/summary/aggrigate/?'
            url += `start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&interval=${this.selectedInterval.key}&filter_type=${this.selectedTab}`
            
            this.dataLoader = true;
            this.viewAggrigateData = ''
            this.processedTopBoxesData = ''
            axios.get(url).then((res) => {
                this.viewAggrigateData = res.data
                this.processedTopBoxesData= res.data.total
                this.$nextTick(() => {
                    this.processTable('#reports_table',4)
                })
                this.generateMetricsForFilter();
                this.dataLoader = false
            }).catch((err) => {
                console.log(err);
                this.dataLoader = false;
            });
        },

        processTable(id,sortCol=2) {
            $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                responsive: true,
                info: false,
                scrollCollapse: true,
                fixedColumns: {
                    left:1
                },
                // sorting: [[sortCol, "desc"]],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            $(id + ' thead th:first-child').removeClass('sorting sorting_asc sorting_desc');
        },
    },

}