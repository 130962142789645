import { render, staticRenderFns } from "./PurchaseJourney.vue?vue&type=template&id=16931b54&scoped=true&lang=pug"
import script from "./PurchaseJourney.js?vue&type=script&lang=js&external"
export * from "./PurchaseJourney.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16931b54",
  null
  
)

export default component.exports